import React from 'react'
import { NavTop } from '../common/nav/top'
import { NavBottom } from '../common/nav/bottom'
import { Box, createTheme, CssBaseline, styled, ThemeProvider, useMediaQuery } from '@mui/material'
import { Outlet } from 'react-router-dom'
import { getThemeOptions } from '../common/theme'
import { ColorModeContext } from '../common/contexts/colorModeContext'

export const StyledBox = styled(Box)(({ theme }) => ({
  minHeight: `calc(100vh - 2 * (${theme.spacing(8)}))`,
  height: `calc(100vh - 2 * (${theme.spacing(8)}))`,
  background: theme.palette.background.default
}))

function Root () {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)')

  const [mode, setMode] = React.useState<'light' | 'dark'>(prefersDarkMode ? 'dark' : 'light')
  const colorMode = React.useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'))
      }
    }),
    []
  )

  const theme = React.useMemo(
    () =>
      createTheme(getThemeOptions(mode)),
    [mode]
  )

  theme.typography.body1 = {
    ...theme.typography.body1,
    [theme.breakpoints.down('md')]: {
      fontSize: '0.8rem'
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.8rem'
    }
  }

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline/>
        {/* <NavTop /> */}
        <StyledBox>
          <Outlet/>
        </StyledBox>
        <NavBottom />
      </ThemeProvider>
    </ColorModeContext.Provider>
  )
}

export default Root
