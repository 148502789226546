import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import reportWebVitals from './reportWebVitals'

import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import ErrorPage from './errorPage'
import Root from './routes/root'
import Home from './routes/home'
import { ProfilePage } from './routes/profile'
import { Services } from './routes/services'
import { Process } from './routes/process'
import { Case } from './routes/cases'
import { Contact } from './routes/contact'
import { CasesList } from './routes/casesList'

import { caseLoader } from './common/loaders/caseLoader'
import { casesPathName, contactPathName, processPathName, profilePathName, servicesPathName } from './routes'

const router = createBrowserRouter([
  {
    path: '/',
    element: <Root/>,
    errorElement: <ErrorPage/>,
    children: [
      {
        path: '',
        element: <Home />
      }
      // {
      //   path: profilePathName,
      //   element: <ProfilePage/>
      // },
      // {
      //   path: servicesPathName,
      //   element: <Services/>
      // },
      // {
      //   path: processPathName,
      //   element: <Process/>
      // },
      // {
      //   path: casesPathName,
      //   element: <CasesList />
      // },
      // {
      //   path: `${casesPathName}/:caseId`,
      //   element: <Case />,
      //   loader: caseLoader
      // },
      // {
      //   path: contactPathName,
      //   element: <Contact/>
      // }

    ]
  }

])

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
)

root.render(
  <React.StrictMode>
    <RouterProvider router={router}/>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
