import React from 'react'
import { Backdrop, Box, Card, Paper, Typography } from '@mui/material'

export default function Home () {
  return (

    <Box sx={{
      position: 'absolute',
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      background: `url(${process.env.PUBLIC_URL + '/images/naest.jpg'}) no-repeat center center fixed`,
      backgroundSize: 'cover'
    }}>

      <Backdrop open={true}>
        <Paper sx={{ p: 2, opacity: 0.8 }}>
          <Typography variant='h4'>
            Virksomheden er midlertidigt lukket grundet barsel
          </Typography>
        </Paper>
      </Backdrop>

    </Box>
  )
}
